import React from "react"
import MainLayout from "../components/mainlayout"
import SEO from "../components/seo.js"

export default () => (
    <MainLayout>
      <SEO title="404" />
      <h1>
        404: Your request page was not found.
      </h1>
      <div>
        "I thought what I'do was, I'd pretend I was one of those deaf-mutes" <br />
        The Catcher in the Rye, 1951, J.D. Salinger
      </div>
      <div style={{ margin: "30px 0 0 0" }}>
        or should I?
      </div>
    </MainLayout>
)
